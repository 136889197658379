$widescreen-enabled: false;
$fullhd-enabled: false;
@import "~bulma/bulma";
@import "_base.scss";

@import "_section.scss";
@import "_hero.scss";

.icon-with-word {
  white-space: nowrap;
}
